import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

export const AppContext = createContext();

function UserProvider({ children }) {
  const location = useLocation();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(() => {
    return JSON.parse(sessionStorage.getItem('token')) || false;
  });
  const [remember, setRemember] = useState(() => {
    return JSON.parse(sessionStorage.getItem('remember')) || false;
  });

  useEffect(() => {
    const currentPath = location.pathname + location.search;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      page: {
        path: currentPath,
      },
    });
  }, [location]);

  const getExpirationDate = token => {
    const { exp } = JSON.parse(atob(token.split('.')[1]));
    return exp * 1000;
  };

  const isTokenExpired = token => {
    const expirationDate = getExpirationDate(token);
    return Date.now() > expirationDate;
  };

  const checkTokenExpiration = token => {
    const isExpired = isTokenExpired(token);

    if (isExpired) {
      sessionStorage.removeItem('token');
      setToken(false);
      history.push('/');
    }
  };

  const doLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('remember');

    setToken(false);
    setRemember(false);

    history.push('/');
  };

  return (
    <AppContext.Provider
      value={{
        token,
        remember,
        isLoading,
        setToken,
        setRemember,
        checkTokenExpiration,
        doLogout,
        setIsLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      'You must surround your components with a CustomerProvider before call useCustomer',
    );
  }

  return context;
};

export default UserProvider;
