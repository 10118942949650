import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import ProtectedRoutes from '../ProtectedRoutes';
import UserProvider from '../../providers/UserProvider';

const Agents = lazy(() => import('../../pages/Agents'));
const AgentCreate = lazy(() => import('../../pages/Agents/Create'));
const AgentEdit = lazy(() => import('../../pages/Agents/Edit'));
const Contracts = lazy(() => import('../../pages/Contracts'));
const ContractCreate = lazy(() => import('../../pages/Contracts/Create'));
const ContractEdit = lazy(() => import('../../pages/Contracts/Edit'));
const Faqs = lazy(() => import('../../pages/Faqs'));
const FaqCreate = lazy(() => import('../../pages/Faqs/Create'));
const FaqEdit = lazy(() => import('../../pages/Faqs/Edit'));
const Categories = lazy(() => import('../../pages/Categories'));
const CategoriesCreate = lazy(() => import('../../pages/Categories/Create'));
const CategoriesEdit = lazy(() => import('../../pages/Categories/Edit'));
const Home = lazy(() => import('../../pages/Home'));
const Login = lazy(() => import('../../pages/Login'));
const Monocard = lazy(() => import('../../pages/Monocard'));
const MonocardCreate = lazy(() => import('../../pages/Monocard/Create'));
const MonocardEdit = lazy(() => import('../../pages/Monocard/Edit'));
const Users = lazy(() => import('../../pages/Users'));
const UsersCreate = lazy(() => import('../../pages/Users/Create'));
const UsersEdit = lazy(() => import('../../pages/Users/Edit'));

function MainRoutes() {
  return (
    <Suspense fallback={<div>Carregando</div>}>
      <Router>
        <UserProvider>
          <Switch>
            <Route path="/" exact component={Login} />
            <ProtectedRoutes path="/dashboard" exact component={Home} />
            <ProtectedRoutes path="/agents" exact component={Agents} />
            <ProtectedRoutes
              path="/agents/create"
              exact
              component={AgentCreate}
            />
            <ProtectedRoutes
              path="/agents/edit/:slug"
              exact
              component={AgentEdit}
            />
            <ProtectedRoutes path="/faqs" exact component={Faqs} />
            <ProtectedRoutes path="/faqs/create" exact component={FaqCreate} />
            <ProtectedRoutes path="/faqs/edit/:id" exact component={FaqEdit} />
            <ProtectedRoutes path="/categories" exact component={Categories} />
            <ProtectedRoutes
              path="/categories/create"
              exact
              component={CategoriesCreate}
            />
            <ProtectedRoutes
              path="/categories/edit/:id"
              exact
              component={CategoriesEdit}
            />
            <ProtectedRoutes path="/contracts" exact component={Contracts} />
            <ProtectedRoutes
              path="/contracts/create"
              exact
              component={ContractCreate}
            />
            <ProtectedRoutes
              path="/contracts/edit/:id"
              exact
              component={ContractEdit}
            />
            <ProtectedRoutes path="/monocard" exact component={Monocard} />
            <ProtectedRoutes
              path="/monocard/register"
              exact
              component={MonocardCreate}
            />
            <ProtectedRoutes
              path="/monocard/edit/:id"
              exact
              component={MonocardEdit}
            />
            <ProtectedRoutes path="/users" exact component={Users} />
            <ProtectedRoutes
              path="/users/create"
              exact
              component={UsersCreate}
            />
            <ProtectedRoutes
              path="/users/edit/:id"
              exact
              component={UsersEdit}
            />
          </Switch>
        </UserProvider>
      </Router>
    </Suspense>
  );
}

export default MainRoutes;
